import GitHubLogo from './images/github.webp';
import hyperlinkLogo from './images/hyperlink.webp';

export const Projects = (props) => {

    return (
        <div className = "Projects">
            <h1> My Solo & Team Projects </h1>
                <wrapper>
                    {props.projects.map((project) => {
                        return (
                            <div className = "ProjectContainer">
                                <div style = {{marginTop: '-2vw'}}>
                                    <h1 style = {{marginLeft: '-37vw', fontSize: '3vw'}}>{project.title}</h1>
                                    <hr style = {{marginTop: '-2.25vw', marginLeft: '15vw', marginBottom: '0vw'}}/>
                                </div>
                                <div className = "Description">
                                    <h1 style = {{lineHeight: '4vw', marginTop: '1.5vw', fontFamily: 'Kollektif', fontSize: "2.5vw"}}>{project.description}</h1>
                                    <a href = {project.url} target = "_blank" rel = "noopener noreferrer"><button className = "LogoButton" style = {{position : 'relative', right: '1vw',marginTop: '-5vw', width: '1vw', height: '1vw'}}><img style = {{marginLeft: '-3vw', marginTop: '-3vw', width: '6vw', height: '6vw'}} src = {GitHubLogo} alt = "Git image"/></button></a>
                                    <a href = {project.url2} target = "_blank" rel = "noopener noreferrer"><button className = "LogoButton" style = {{position : 'relative', left: '1vw', marginTop: '-5vw', width: '1vw', height: '1vw'}}><img style = {{marginLeft: '-3vw', marginTop: '-3vw', width: '6vw', height: '6vw'}} src = {hyperlinkLogo} alt = "Link image"/></button></a>
                                </div>
                                <img style = {{marginTop: project.secondPhoto ? '-4.5vw' : '0vw', objectFit: 'cover'}} className = "ProjectImage" src = {project.image} alt = {project.caption} />
                                {project.secondPhoto&&(
                                    <img style = {{marginTop: '-16.75vw', marginLeft: '20vw', objectFit: 'cover'}} className = "ProjectImage" src = {project.image2} alt = {project.caption} />
                                )
                                }
                            </div>
                        );
                    })}
                </wrapper>
        </div>
    );
}