import './App.css';
import { IntroHeader } from './IntroHeader.js';
import { Skills } from './Skills.js';
import { AboutMe } from './AboutMe.js';
import { Projects } from './Projects.js';
import { useState, useEffect } from 'react';
import JamesPhoto1 from './images/jamesphoto1.webp';
import PythonLogo from './images/python.webp';
import JavaLogo from './images/java.webp';
import CLogo from './images/c.webp';
import CSSLogo from './images/css.webp';
import HTMLLogo from './images/html.webp';
import ReactLogo from './images/react.webp';
import JavaScriptLogo from './images/javascript.webp';
import GitLogo from './images/git.webp';
import GitHubLogo from './images/github.webp';
import TypeScriptLogo from './images/typescript.webp';
import FirebaseLogo from './images/firebase.webp';
import VSCodeLogo from './images/vscode.webp';
import isenbergPhoto from './images/isenbergPhoto.webp'
import encorePhoto from './images/encore3.webp'
import encorePhoto2 from './images/encore.webp'
import pummelPixelsMenuPhoto from './images/pummelpixelsmenu.webp'
import pummelPixelsImageInGame from './images/pummelpixelsimageingame.webp'
import jamesPineiroSiteImage from './images/jamespineirositeimage.webp'
import jamesPineiroSiteImageSkills from './images/jamespineirositeimageskills.webp'
import alienAnnihilationImage from './images/alienannihilationphoto.webp'
import alienAnnihilationInGameImage from './images/alienannihilationphotoingame.webp'
import buildAboutImage from './images/buildwebsiteaboutpage.webp'
import buildProjectsImage from './images/buildwebsiteprojects.webp'
import loopIcon from './images/loop.webp'

function App() {
  const headerImages = [JamesPhoto1];
  const logoImages = [{image: PythonLogo, caption: "Python"}, {image: JavaLogo, caption: "Java"}, {image: CLogo, caption: "C"},
  {image: JavaScriptLogo, caption: "JavaScript"},{image: TypeScriptLogo, caption: "TypeScript"}, {image: CSSLogo, caption: "CSS"}, {image: HTMLLogo, caption: "HTML"},
  {image: ReactLogo, caption: "React"}, {image: FirebaseLogo, caption: "Firebase"},
  {image: GitLogo, caption: "Git"}, {image: GitHubLogo, caption: "GitHub"}, {image: VSCodeLogo, caption: "VSCode"}];
  const [loadedHeaderImages, setLoadedHeaderImages] = useState([]);
  const [loadedLogoImages, setLoadedLogoImages] = useState([]);
  const projectImages = [{title: "Loop", description: "Get out of social media, stay in the Loop. If you want to stay informed without social media or predatory article subscription services, this is your new favorite app. I worked solo for 26 hours to develop Loop in React Native for HackUMass 12, and was the only solo team to win a category with Best Mobile App!", image: loopIcon, image2: loopIcon, caption: "Photo of Loop", url: "https://github.com/jamespineiro/loop", url2: "https://devpost.com/software/loop-5p83qv", secondPhoto: false},
                        {title: "BUILD Site Upkeep", description: "Collaborates as a team with peers to update and polish BUILD UMass's website. We develop with React.js and utilize Git for version control. I have been working on this project since September 2024, and we will wrap up its development in May 2025. We also communicate with Slack for organization.", image: buildAboutImage, image2: buildProjectsImage, caption: "Photo of BUILD site", url: "https://github.com/build-umass/BUILD-Website", url2: "https://www.buildumass.com/", secondPhoto: true},
                        {title: "Pummel Pixels", description: "Learned the Godot Game Engine entirely alone to develop a platform fighter video game. I've developed this project with GDScript, and I use Git for version control. It can be played with up to four players, and I've put 150+ hours on it since June 2024. (Steam page up soon)", image: pummelPixelsMenuPhoto, image2: pummelPixelsImageInGame, caption: "Photo of Pummel Pixels", url: "https://github.com/jamespineiro/Pummel-Pixels", url2: "https://www.jamespineiro.com", secondPhoto: true},
                        {title: "Jamespineiro.com", description: "Developed my first major React.js project that wasn't for a BUILD UMass client. I spent about 20-25 hours on this website, as it has gone through a few iterations. I use Git for version control, and have worked on it here and there since March 2024. ", image: jamesPineiroSiteImage, image2: jamesPineiroSiteImageSkills, caption: "Photo of website", url: "https://github.com/jamespineiro/personalwebsite", url2: "https://www.jamespineiro.com/", secondPhoto: true},
                        {title: "Alien Anihilation", description: "Created a boss battle video game for HackUMass11, and did it solo in Python. I spent 22 hours straight from November 11th to November 12th 2023 drawing, animating, and programming to bring this wonderful game to life! Try it now on itch.io!", image: alienAnnihilationImage, image2: alienAnnihilationInGameImage, caption: "Photo of Alien Anihilation", url: "https://github.com/jamespineiro/alienanihilation", url2: "https://jpineiro.itch.io/alien-annihilation", secondPhoto: true},
                        {title: "Encore", description: "Spent 120+ hours from September 2023 to March 2024 developing an arcade video game in order to improve in both Python and my marketing skills. I also developed an image caching system which reduced loading times by around 60%. ", image: encorePhoto, image2: encorePhoto2, caption: "Photo of Encore", url: "https://github.com/jamespineiro", url2: "https://store.steampowered.com/app/2674680/Encore/", secondPhoto: true}];
  const [loadedProjectImages, setLoadedProjectImages] = useState([]);

  const preloadImages = () => {
    setLoadedHeaderImages(
      headerImages.map((image) => {
        const img = new Image();
        img.src = image;
        return img.src;
      })
    );
    setLoadedLogoImages(
      logoImages.map((logoImage) => {
        const img = new Image();
        img.src = logoImage.image;
        return {image: img.src, caption: logoImage.caption};
      })
    );
    setLoadedProjectImages(
      projectImages.map((projectImage) => {
        const img = new Image();
        img.src = projectImage.image;
        const img2 = new Image();
        img2.src = projectImage.image2;
        return {title : projectImage.title, description: projectImage.description, image: img.src, image2: img2.src, caption: projectImage.caption, url: projectImage.url, url2: projectImage.url2, secondPhoto: projectImage.secondPhoto};
      })
    );
  };

  useEffect(() => {
    preloadImages();
  },[]);

  return (
    <div className="App">
      <IntroHeader images = {loadedHeaderImages}/>
      <div className = "PrimaryContent">
        <AboutMe image = {isenbergPhoto}/>
        <Skills logos = {loadedLogoImages}/>
        <Projects projects = {loadedProjectImages}/>
      </div>
      <div className = "App-footer">
        <footer>
          <p> © 2024 James Piñeiro </p>
        </footer>
      </div>
    </div>
  );
}

export default App;
