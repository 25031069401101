

export const Skills = (props) => {
    return (
        <div className = "Introduction">
            <h1> My Skills & Tools </h1>
            <div className = "Skills">
            {props.logos.map((logo) => {
                return (
                    <div className = "Logo">
                        <img src = {logo.image} alt = "Python, Java, C" />
                        <p> {logo.caption} </p>
                    </div>
                );
            })}
            </div>
        </div>
    );
}