import GitHubLogo from './images/github.webp';
import LinkedInLogo from './images/linkedin.webp';

export const IntroHeader = (props) => {
    
    return (
        <div style = {{backgroundImage: `url(${props.images[0]})`}}>
            <header className="App-header" style = {{backgroundImage: `url(${props.images[0]})`}}>
            <a href = "https://github.com/jamespineiro" target = "_blank" rel = "noopener noreferrer"><button className = "LogoButton" style = {{position : 'relative', left: '3vw', marginTop : '-8vw', float: 'left', width: '1vw', height: '1vw'}}><img style = {{marginLeft: '-3vw', marginTop: '-3vw', width: '6vw', height: '6vw'}} src = {GitHubLogo} alt = "Git image"/></button></a>
            <a href = "https://www.linkedin.com/in/jamespineiro" target = "_blank" rel = "noopener noreferrer"><button className = "LogoButton" style = {{position : 'relative', right: '3vw', marginTop : '-8vw', float: 'right', width: '1vw', height: '1vw'}}><img style = {{marginLeft: '-3vw', marginTop: '-3vw', width: '6vw', height: '6vw'}} src = {LinkedInLogo} alt = "Git image"/></button></a>
                <p>
                    It's so nice to meet you! I'm James Piñeiro, a Software Engineer based near Boston, Massachusetts.
                </p>
                <a href = "mailto:jamespineirojobs@gmail.com"> <button className = "ContactButton"> Message Me </button></a>
            </header>
        </div>
    );
}