

export const AboutMe = (props) => {
    return (
        <div className = "AboutMe">
            <h1> About Me </h1>
            <hr/>
            <img className = "Suited" src = {props.image} alt = "Photo of me smiling" />
            <p>
                I'm an outgoing, resilient Computer Science honors student at the University
                of Massachusetts Amherst. I'm dedicated to my craft 
                and use programming as a creative outlet.
            </p>
        </div>
    )
}